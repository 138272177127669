var decisionTreeCaseNumber = 0;
var decisionTreeDeterminationLevel = 1;
function setDeterminationLevel(l) {
    decisionTreeDeterminationLevel = l;
}
function setCoverageDeterminationID(id) {
    decisionTreeCaseNumber = id;
    return false;
}
function setCaseNumber(id) {
    decisionTreeCaseNumber = id;
    return false;
}
function getDecisionTreeAnswersForLegacy() {
    var answers = getAnswers();
    return JSON.stringify(answers);
}
function getDecisionTreeAnswers() {
    var answers = getAnswers();
    var result = {};
    result.DeterminationLevel = decisionTreeDeterminationLevel;
    result.CoverageDeterminationID = decisionTreeCaseNumber;
    result.CaseID = decisionTreeCaseNumber;
    result.Results = JSON.stringify(answers);
    return result;
}
var requestId = null;
function notifySave() {
    if (document.getElementById("DecisionTreeResults") !== null) {
        document.getElementById("DecisionTreeResults").value = JSON.stringify(getDecisionTreeAnswers());
    } else {
        console.log(getDecisionTreeAnswers());
    }
}
function unlockInteractiveQuestionAnswerSection() {
    document.getElementById("interactiveQuestionAnswerSection").classList.remove("complete");
}
function lockInteractiveQuestionAnswerSection() {
    document.getElementById("interactiveQuestionAnswerSection").classList.add("complete");
}

function generateProviderAnswerSection(epaQS) {
    var s = "<div class='providerQuestionAnswerSection'>";
    s += "<div class='sectionTitle'>Prescriber Answers</div>";
    s += "<div class='treeName'>" + epaQS.questionSetHeader.title + "</div >";
    epaQS.questions.forEach(q => {
        var isAnswered = false;
        var qt = q.sequenceNumber + ". " + q.text + "<br>";
        
        var out = "";
        try {
            if (q.questionType.isMultiple !== undefined) {
                var type = q.questionType.isMultiple ? 'checkbox' : 'radio'; ``
                q.questionType.answer?.select?.forEach(feac => {
                    var answered = q.questionType.choice.filter(f => f.id == feac.choiceID);
                    if (answered !== undefined) { isAnswered = true; }
                    out += "<input type=" + type + " disabled checked>" + answered[0].choiceText + "<br />"
                    if (feac.additionalFreeText != null && feac.additionalFreeText != answered[0].choiceText)
                        out += " &nbsp; &nbsp; &nbsp; " + feac.additionalFreeText
                });
            } else if (q.questionType.isFreeText !== undefined) {
                if (q.questionType?.answer?.submitterProvidedAnswer != undefined && q.questionType?.answer?.submitterProvidedAnswer != "") { isAnswered = true; }
                out += "<input type=text value='" + (q.questionType?.answer?.submitterProvidedAnswer ?? "") + "' disabled></input>";
            } else if (q.questionType.isDateTimeRequired !== undefined) {
                var d1, t1;
                if (!q.questionType.isDateTimeRequired && q.questionType?.answer?.date?.date != undefined) {
                    d1 = (q.questionType?.answer?.date?.date).substring(0, 10);
                } else if (q.questionType.isDateTimeRequired && q.questionType?.answer?.date?.dateTime != undefined) {
                    d1 = (q.questionType?.answer?.date?.dateTime).substring(0, 10);
                    t1 = (q.questionType?.answer?.date?.dateTime).substring(11);
                }
                if (d1 != undefined && d1 != "") { isAnswered = true; }
                out += "<input ' type=date value='" + d1 + "'  disabled></input>";
                if (q.questionType.isDateTimeRequired) {
                    out += " Time: " + "<input type=time value='" + t1 + "' disabled></input>";
                }
            } else if (q.questionType.isNumeric !== undefined) {
                if (q.questionType?.answer?.submitterProvidedNumericAnswer != undefined && q.questionType?.answer?.submitterProvidedNumericAnswer != "") { isAnswered = true; }
                out += "<input type=text value='" + (q.questionType?.answer?.submitterProvidedNumericAnswer ?? "") + "' disabled></input><BR>";
            }
        } catch {
            // question is unanswered or violates answer conventions
            s+= "<div class=debug>unanswered:"+q.id+"</div>"
        }
        if (isAnswered) {
            s += qt + out + "</br>"
        }
    });
    s += "</div>";
    return s;
}