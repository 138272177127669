const overrideCodeStaticList = [
    {
        "Code": "180",
        "Reason": "Override Date Prescription Written limit -180 days"
    },
    {
        "Code": "250",
        "Reason": "Override $250 ingredient cost limit"
    },
    {
        "Code": "4PER",
        "Reason": "Override tablet and capsule 4 per day limit"
    },
    {
        "Code": "4UPD",
        "Reason": "Override 4 or more units per day limit"
    },
    {
        "Code": "ALL",
        "Reason": "Overrides all logics that are overridable."
    },
    {
        "Code": "COB",
        "Reason": "Override coordination of benefit"
    },
    {
        "Code": "COBSP",
        "Reason": "Ovveride Coordination Of Benefit "
    },
    {
        "Code": "CPD",
        "Reason": "Override NonFormulary Conditional Part D"
    },
    {
        "Code": "CPDF",
        "Reason": "Override Formulary Conditional Part D"
    },
    {
        "Code": "DAW",
        "Reason": "Dispense As Written"
    },
    {
        "Code": "DAYS",
        "Reason": "Override Formulary day limit"
    },
    {
        "Code": "DDIP",
        "Reason": "Override Drug-Drug Interaction Program"
    },
    {
        "Code": "DESI",
        "Reason": "DESI Coverage Override"
    },
    {
        "Code": "DOP",
        "Reason": "Override dose optimization program"
    },
    {
        "Code": "DR",
        "Reason": "Allow prescriber"
    },
    {
        "Code": "DS",
        "Reason": "Allows to change days supply according to plan parameters"
    },
    {
        "Code": "DT",
        "Reason": "Override duplicate therapy DUR edit"
    },
    {
        "Code": "DTPE",
        "Reason": "Override Transition Supply Exhausted"
    },
    {
        "Code": "DUR",
        "Reason": "Override DUR rejects"
    },
    {
        "Code": "DURAPAP",
        "Reason": "Allow exceeded APAP Quantities"
    },
    {
        "Code": "DURAPAP-Appeal",
        "Reason": "Allow exceeded APAP Quantities"
    },
    {
        "Code": "DURASA",
        "Reason": "Allow Exceeded ASA Quantities"
    },
    {
        "Code": "DURMED",
        "Reason": "Allow exceeded Morphine quantities"
    },
    {
        "Code": "DURMED-2",
        "Reason": "Allow exceeded Morphine quantities - 2"
    },
    {
        "Code": "DURMED-Appeal",
        "Reason": "Allow exceeded Morphine quantities - Appeal"
    },
    {
        "Code": "DURMED-DS",
        "Reason": "Allow exceeded Morphine quantities - Drug Specific"
    },
    {
        "Code": "EARLY",
        "Reason": "Allow early refill"
    },
    {
        "Code": "EARLY-BM",
        "Reason": "Allows early refills if medication is broken"
    },
    {
        "Code": "EARLY-DC",
        "Reason": "Allow early refill for same medication - change in dosage / frequency"
    },
    {
        "Code": "EARLY-DT",
        "Reason": "Allow early refill - duplicate therapy"
    },
    {
        "Code": "EARLY-IDS",
        "Reason": "Allow early refill - incorrect days supply"
    },
    {
        "Code": "EARLY-LM",
        "Reason": "Allows early refills if medication is lost"
    },
    {
        "Code": "EARLY-OT",
        "Reason": "Allows early refills for other reasons"
    },
    {
        "Code": "EARLY-SM",
        "Reason": "Allows early refills if medication is stolen"
    },
    {
        "Code": "EARLY-VS",
        "Reason": "Allows early and additional refills-Copy of travel tickets is required"
    },
    {
        "Code": "EXCEP",
        "Reason": "Override plan exceptions or exclusions"
    },
    {
        "Code": "INIT",
        "Reason": "Override 'no initial fill' requirement on refills"
    },
    {
        "Code": "LAPSE",
        "Reason": "Override lapse in therapy when refill is late"
    },
    {
        "Code": "LT",
        "Reason": "Override Lifetime Formulary limits requirement"
    },
    {
        "Code": "LTBS",
        "Reason": "Override the Benefit Segregation rejection on a Lifetime"
    },
    {
        "Code": "LTDS",
        "Reason": "Override the days supply rejection on a Lifetime"
    },
    {
        "Code": "LTDUR",
        "Reason": "Override the DUR rejection on a Lifetime"
    },
    {
        "Code": "LTPA",
        "Reason": "Override the PA rejection on a Lifetime"
    },
    {
        "Code": "LTPE",
        "Reason": "Override the Drug Not Covered rejection on a Lifetime"
    },
    {
        "Code": "LTQL",
        "Reason": "Override Lifetime Formulary quantity limits requirement"
    },
    {
        "Code": "LTQL-Appeal",
        "Reason": "Override Lifetime Formulary quantity limits requirement by Appeal"
    },
    {
        "Code": "LTQL-LOC",
        "Reason": "Override Lifetime Formulary quantity limits requirement for LOC"
    },
    {
        "Code": "MCAI",
        "Reason": "Reroute claim through MCAI Benefit"
    },
    {
        "Code": "MDR",
        "Reason": "Missing Doctor ID"
    },
    {
        "Code": "MEDB",
        "Reason": "Reroute claim through MEDB Benefit"
    },
    {
        "Code": "MEDD",
        "Reason": "Reroute claim through MEDD Benefit"
    },
    {
        "Code": "MEDREB",
        "Reason": "Medicaid Rebatable Drugs"
    },
    {
        "Code": "NCOVERED",
        "Reason": "Override Product Service Not Covered"
    },
    {
        "Code": "NDTPE",
        "Reason": "Override Non Part D Transition Supply Exhausted"
    },
    {
        "Code": "NG",
        "Reason": "Allows submission of Brand drug"
    },
    {
        "Code": "NM15",
        "Reason": "Override non-maintenance drugs 15 day limit"
    },
    {
        "Code": "NMNDC",
        "Reason": "Non-Matched NDC FDA List"
    },
    {
        "Code": "NSDE",
        "Reason": "FDA NSDE Source"
    },
    {
        "Code": "OAL",
        "Reason": "Override Age Limit"
    },
    {
        "Code": "OBOCD",
        "Reason": "Override Basis of Cost Determination"
    },
    {
        "Code": "OCCED",
        "Reason": "Override Compound Cover Excluded Drugs"
    },
    {
        "Code": "OCIIFILL",
        "Reason": "Override Schedule II Refill Edit"
    },
    {
        "Code": "OCLRST",
        "Reason": "Override Claim Restriction Category Exception"
    },
    {
        "Code": "OCLRSTAR",
        "Reason": "Override Claim Restriction Age Restriction"
    },
    {
        "Code": "OCLRSTDAPF",
        "Reason": "Override Claim Restriction Days After Previous Fill"
    },
    {
        "Code": "OCLRSTDC",
        "Reason": "Override Claim Restriction Diagnosis Code"
    },
    {
        "Code": "OCLRSTHDSM",
        "Reason": "Override Claim Restriction History Drug from Same Manufacturer"
    },
    {
        "Code": "OCLRSTQD",
        "Reason": "Override Claim Restriction Quantity Dispensed"
    },
    {
        "Code": "OCLRSTSCC",
        "Reason": "Override Claim Restriction SCC"
    },
    {
        "Code": "OCOBC",
        "Reason": "Override COB Configuration"
    },
    {
        "Code": "OCOMP",
        "Reason": "Override Compound Rejection"
    },
    {
        "Code": "OCOMPDR",
        "Reason": "Override Compound Drug Restriction"
    },
    {
        "Code": "OCOPAY",
        "Reason": "Override Copayment"
    },
    {
        "Code": "OCOPAY-Admin",
        "Reason": "Override Copayment"
    },
    {
        "Code": "OCP",
        "Reason": "Override Coupon Program"
    },
    {
        "Code": "OCPCDURD",
        "Reason": "Override for Clinical Program - Concurrent DUR - Drugs"
    },
    {
        "Code": "OCPCDURP",
        "Reason": "Override for Clinical Program - Concurrent DUR - Providers"
    },
    {
        "Code": "OCPDDI",
        "Reason": "Override for Clinical Program - Drug-Drug Interaction"
    },
    {
        "Code": "OCPDI",
        "Reason": "Override for Clinical Program - Duplicate Ingredient"
    },
    {
        "Code": "OCPDT",
        "Reason": "Override for Clinical Program - Duplicate Therapy"
    },
    {
        "Code": "OCPN",
        "Reason": "Override Closed Prescriber Network"
    },
    {
        "Code": "OCRA",
        "Reason": "Override Compounds Route of Administration"
    },
    {
        "Code": "OCTO",
        "Reason": "Override Claim Too Old"
    },
    {
        "Code": "ODA",
        "Reason": "Allows to enter specific  dollar quantity"
    },
    {
        "Code": "ODAL",
        "Reason": "Override Drug Age Limitation"
    },
    {
        "Code": "ODAR",
        "Reason": "Override DUR Adverse Reaction edit"
    },
    {
        "Code": "ODAWGP",
        "Reason": "Override DAW to Generic Pricing"
    },
    {
        "Code": "ODAWMP",
        "Reason": "Override DAW to MAC Pricing"
    },
    {
        "Code": "ODAWRP",
        "Reason": "Override DAW to Regular Pricing"
    },
    {
        "Code": "ODCS",
        "Reason": "Override Drug Coverage Status"
    },
    {
        "Code": "ODCV",
        "Reason": "Override Diagnosis Codes Validations"
    },
    {
        "Code": "ODDA",
        "Reason": "Override DUR Allergy edit"
    },
    {
        "Code": "ODDBOA",
        "Reason": "Override Daily Dosage Based on Age"
    },
    {
        "Code": "ODDD",
        "Reason": "Override DUR Drug Interaction edit"
    },
    {
        "Code": "ODDF",
        "Reason": "Override DUR Food Interaction edit"
    },
    {
        "Code": "ODDLEX",
        "Reason": "Override Daily Dosing Limitation Exclusion"
    },
    {
        "Code": "ODDR",
        "Reason": "Override Drug Dispensing Restriction"
    },
    {
        "Code": "ODEAPEXCL",
        "Reason": "Override DEA Prescriber Exclusion"
    },
    {
        "Code": "ODHD",
        "Reason": "Override High Dose DUR edit"
    },
    {
        "Code": "ODID",
        "Reason": "Override DUR Ingredient Duplication edit"
    },
    {
        "Code": "ODLBU",
        "Reason": "Override Dispensed Limitation Based on Utilization Logic"
    },
    {
        "Code": "ODLD",
        "Reason": "Override DUR Low Dose edit"
    },
    {
        "Code": "ODMC",
        "Reason": "Override DUR Drug-Disease edit"
    },
    {
        "Code": "ODMN",
        "Reason": "Override DUR Insufficient Duration edit "
    },
    {
        "Code": "ODMX",
        "Reason": "Override DUR Excessive Duration edit"
    },
    {
        "Code": "ODPA",
        "Reason": "Override DUR Age edit"
    },
    {
        "Code": "ODPR",
        "Reason": "Override DUR Prior Adverse reaction edit"
    },
    {
        "Code": "ODQD",
        "Reason": "Override for Decimal in Quantity Dispensed"
    },
    {
        "Code": "ODSLTC",
        "Reason": "Override Days Supply for LTC"
    },
    {
        "Code": "ODSX",
        "Reason": "Override DUR Sex edit"
    },
    {
        "Code": "ODTBTP",
        "Reason": "Override Duplicate Therapy Based on Therapy Patterns Logic"
    },
    {
        "Code": "ODTD",
        "Reason": "Override DUR Duplicate Therapy edit"
    },
    {
        "Code": "ODW",
        "Reason": "Override date written limits"
    },
    {
        "Code": "OEMV",
        "Reason": "Override Excluded Manufacturer Validation"
    },
    {
        "Code": "OFFQD",
        "Reason": "Override First Fill Quantity Dispensed"
    },
    {
        "Code": "OGPL",
        "Reason": "Override Grace Period Logic"
    },
    {
        "Code": "OHCRP",
        "Reason": "Override Health Care Reform Program"
    },
    {
        "Code": "OHD",
        "Reason": "Override High Dose DUR edit"
    },
    {
        "Code": "OHPRPI",
        "Reason": "Override Hospice By Patient Residence or Patient Indicator"
    },
    {
        "Code": "OICAV",
        "Reason": "Override Ingredient Cost Accepted Values"
    },
    {
        "Code": "OIFDCPWSW",
        "Reason": "Override Incremental Fill Diagnosis Code Prescription Written Submission Window"
    },
    {
        "Code": "OIFDCSW",
        "Reason": "Override Incremental Fill Diagnosis Code Submission Window"
    },
    {
        "Code": "OIFSDOS",
        "Reason": "Override Incremental Fill Same DOS"
    },
    {
        "Code": "OIS",
        "Reason": "Override Inactive Status Drugs"
    },
    {
        "Code": "OLEIE",
        "Reason": "Override Submitted Is Associated With A Sanctioned Prescriber"
    },
    {
        "Code": "OLTCC",
        "Reason": "Override LTC Contract"
    },
    {
        "Code": "OLTCPR",
        "Reason": "Allow claims from Pharmacies in PR with PRC 03"
    },
    {
        "Code": "OLTCSCC",
        "Reason": "Override LTC SCC Combinations"
    },
    {
        "Code": "OMAINT",
        "Reason": "Override Maintenance"
    },
    {
        "Code": "OMCPN",
        "Reason": "Override Member Closed Prescriber Network logic"
    },
    {
        "Code": "OMCPNWPN",
        "Reason": "Override Member Closed Prescriber Network Without Prescriber Network"
    },
    {
        "Code": "OMDSNFL",
        "Reason": "Override to Mandatory Days Supply for Next Fill Logic"
    },
    {
        "Code": "OMIDC",
        "Reason": "Override Missing Invalid Diagnosis Code"
    },
    {
        "Code": "OMPNF",
        "Reason": "Override Mandatory Pharmacy for Next Fill Logic"
    },
    {
        "Code": "OMR",
        "Reason": "Override Max Refill Edit"
    },
    {
        "Code": "ONAIVE",
        "Reason": "Override the Naive DS Limit"
    },
    {
        "Code": "ONAIVE-Appeal",
        "Reason": "Override the Naive DS Limit-Appeal"
    },
    {
        "Code": "ONEDSFF",
        "Reason": "Override Non Extended DS on First Fill"
    },
    {
        "Code": "ONM",
        "Reason": "Allows non-maintenance drug for more than 15 days"
    },
    {
        "Code": "ONN",
        "Reason": "Override Negative Network"
    },
    {
        "Code": "ONSDSP",
        "Reason": "Override to allow non-specialty drug in specialty pharmacy"
    },
    {
        "Code": "OODS",
        "Reason": "Override OTC Days Supply Limit"
    },
    {
        "Code": "OONP",
        "Reason": "Override Out Of Network Pharmacy"
    },
    {
        "Code": "OOPCOB",
        "Reason": "Override OPAP and OPPRA rejection"
    },
    {
        "Code": "OOREFILL",
        "Reason": "Override Only Refills Rule"
    },
    {
        "Code": "OP",
        "Reason": "Override prescriber  edits"
    },
    {
        "Code": "OPARTD",
        "Reason": "Override Part D property"
    },
    {
        "Code": "OPCNT",
        "Reason": "Override Pharmacy Contract"
    },
    {
        "Code": "OPCS",
        "Reason": "Override Prescriber Controlled Substance"
    },
    {
        "Code": "OPCSNF",
        "Reason": "Override Prescriber Controlled Substance Not Found"
    },
    {
        "Code": "OPD",
        "Reason": "Override Prescriber Deceased Date and Date of Service Comparison"
    },
    {
        "Code": "OPDDW",
        "Reason": "Override Prescriber Deceased Date and Date Written Comparison"
    },
    {
        "Code": "OPDR",
        "Reason": "Override Provider dispense restriction"
    },
    {
        "Code": "OPEN",
        "Reason": "Override Prescriber Edit Network"
    },
    {
        "Code": "OPENGPPHA",
        "Reason": "Override Provider Enrollment Grace Period For Pharmacies"
    },
    {
        "Code": "OPENGPPHY",
        "Reason": "Override Provider Enrollment Grace Period For Prescribers"
    },
    {
        "Code": "OPENPHA",
        "Reason": "Override Provider Enrollment Pharmacy Validation"
    },
    {
        "Code": "OPENPHY",
        "Reason": "Override Provider Enrollment Prescriber Validation"
    },
    {
        "Code": "OPES",
        "Reason": "Override Prescriber Edit Specialty"
    },
    {
        "Code": "OPEV",
        "Reason": "Override Prescriber Enrollment Validation"
    },
    {
        "Code": "OPFIXPR",
        "Reason": "Override Provider Fixed Price"
    },
    {
        "Code": "OPFSW",
        "Reason": "Override Partial Fill Submission Window"
    },
    {
        "Code": "OPHMAD",
        "Reason": "Override Pharmacy Allowed Drug Formulary Edit"
    },
    {
        "Code": "OPI",
        "Reason": "Override Missing/Invalid Physician ID"
    },
    {
        "Code": "OPIE",
        "Reason": "Override Prescriber Inactive or Expired NPI"
    },
    {
        "Code": "OPLE",
        "Reason": "Override Plan Limitation Exceeded"
    },
    {
        "Code": "OPNA",
        "Reason": "Override Prescriber Non Authorized"
    },
    {
        "Code": "OPNA-IT",
        "Reason": "Override Prescriber Non Authorized - Excluded Taxonomy"
    },
    {
        "Code": "OPPLOCKIN",
        "Reason": "Override for Prescriber and Pharmacy Lock In"
    },
    {
        "Code": "OPPLOCKOUT",
        "Reason": "Override for Prescriber and Pharmacy Lock Out"
    },
    {
        "Code": "OPPNC",
        "Reason": "Override Rejection when primary payer not on claim"
    },
    {
        "Code": "OPPNPI",
        "Reason": "Override Pharmacy Prescriber NPI Comparison"
    },
    {
        "Code": "OPPNS",
        "Reason": "Override Rejection when primary payer not on system"
    },
    {
        "Code": "OPRETH",
        "Reason": "Override Pricing Rule Edit - Threshold"
    },
    {
        "Code": "OPS",
        "Reason": "Override Prescriber Segment Required For Adjudication"
    },
    {
        "Code": "OPTW",
        "Reason": "Override Patient Watcher"
    },
    {
        "Code": "OPWE",
        "Reason": "Override Provider Watch Edit"
    },
    {
        "Code": "OQDGQP",
        "Reason": "Override Quantity Dispense Greater than Quantity Prescribed"
    },
    {
        "Code": "OQL",
        "Reason": "Override Plan Formulary Quantity limit"
    },
    {
        "Code": "OQL-Administrative",
        "Reason": "Override Plan Formulary Quantity limit"
    },
    {
        "Code": "OQL-Appeal",
        "Reason": "Override Plan Formulary Quantity limit by Appeal"
    },
    {
        "Code": "OQL-LOC",
        "Reason": "Override Plan Formulary Quantity limit for LOC"
    },
    {
        "Code": "OQLONN",
        "Reason": "Override Quantity Limitation for Opioid Non-Naive Logic"
    },
    {
        "Code": "OQPNS",
        "Reason": "Override Quantity Prescribed not submitted"
    },
    {
        "Code": "ORE",
        "Reason": "Override Refills Exceed"
    },
    {
        "Code": "ORIAS",
        "Reason": "Override Require Incentive Amount Submitted"
    },
    {
        "Code": "ORP",
        "Reason": "Override Repackage Drug"
    },
    {
        "Code": "OS",
        "Reason": "Override physician specialty"
    },
    {
        "Code": "OSCCBCDNS",
        "Reason": "Override SCC and Basis of Cost Determination not Submitted"
    },
    {
        "Code": "OSCL",
        "Reason": "Override Specialty Contract Limitation"
    },
    {
        "Code": "OSDNSP",
        "Reason": "Override to allow specialty drug in non-specialty pharmacy"
    },
    {
        "Code": "OSFB",
        "Reason": "Override Supplemental Fill Benefit"
    },
    {
        "Code": "OSPF",
        "Reason": "Override Schedule II Partial Fill"
    },
    {
        "Code": "OSPIN",
        "Reason": "Override Scheduled Prescription ID Number"
    },
    {
        "Code": "OSPNW",
        "Reason": "Override Specialty Networks"
    },
    {
        "Code": "OT",
        "Reason": "Allows selection of appropriate Tier"
    },
    {
        "Code": "OT-Administrative",
        "Reason": "Allows selection of appropriate Tier"
    },
    {
        "Code": "OT-Appeal",
        "Reason": "Allows selection of appropriate Tier - Appeal"
    },
    {
        "Code": "OTC",
        "Reason": "Over The Counter"
    },
    {
        "Code": "OTPBVDR",
        "Reason": "Override Transition Part B vs D Rejection"
    },
    {
        "Code": "OUDIPD",
        "Reason": "Override Unit Dose/Institutional Package Drug"
    },
    {
        "Code": "OUP",
        "Reason": "Override Unbreakable Package"
    },
    {
        "Code": "OVP",
        "Reason": "Override Vaccine Program"
    },
    {
        "Code": "PA",
        "Reason": "Override if plan Formulary requires Prior Authorization"
    },
    {
        "Code": "PA-Administrative",
        "Reason": "Override if plan Formulary requires Prior Authorization"
    },
    {
        "Code": "PA-Appeal",
        "Reason": "Override if plan Formulary requires Prior Authorization due to Appeal"
    },
    {
        "Code": "PA-Emergency",
        "Reason": "Override if plan Formulary requires Prior Authorization by Emergency"
    },
    {
        "Code": "PA-Hospice",
        "Reason": "Override if plan Formulary requires Hospice Prior Authorization"
    },
    {
        "Code": "PA-HospiceNoCD",
        "Reason": "Override for when evidence is presented, not related to Hospice"
    },
    {
        "Code": "PA-LOC",
        "Reason": "Override if plan Formulary requires Prior Authorization for LOC"
    },
    {
        "Code": "PA-NotInHospice",
        "Reason": "Override for when the beneficiary is not in Hospice"
    },
    {
        "Code": "PA-PartB",
        "Reason": "Override if plan Formulary requires Prior Authorization"
    },
    {
        "Code": "PA-Transition",
        "Reason": "Override if plan Formulary requires Prior Authorization by Transition"
    },
    {
        "Code": "PARTB",
        "Reason": "Override PART B Drug"
    },
    {
        "Code": "PARTB-PAIDPARTD",
        "Reason": "Part B Drug Paid Under Part D"
    },
    {
        "Code": "PARTBF",
        "Reason": "Use Part B Formulary"
    },
    {
        "Code": "PAW",
        "Reason": "Prior Authorization - Pharmacy Watcher"
    },
    {
        "Code": "PE",
        "Reason": "Allows Drug Coverage by Formulary Exception"
    },
    {
        "Code": "PE-Administrative",
        "Reason": "Allows Drug Coverage by Formulary Exception"
    },
    {
        "Code": "PE-Appeal",
        "Reason": "Allows Drug Coverage by Formulary Exception due to Appeal"
    },
    {
        "Code": "PE-Emergency",
        "Reason": "Allows Drug Coverage by Emergency"
    },
    {
        "Code": "PE-Ley142",
        "Reason": "Allows Drug Coverage by Formulary Exception"
    },
    {
        "Code": "PE-LOC",
        "Reason": "Allows Drug Coverage by Formulary Exception for LOC"
    },
    {
        "Code": "PE-REPACK",
        "Reason": "Allows Drug Coverage when repack"
    },
    {
        "Code": "PE-Transition",
        "Reason": "Allows Drug Coverage by Transition"
    },
    {
        "Code": "PI",
        "Reason": "Override Missing/Invalid Physician ID"
    },
    {
        "Code": "PIE",
        "Reason": "Preclusion Institutional Entity"
    },
    {
        "Code": "PIP",
        "Reason": "Preclusion Individual Prescriber"
    },
    {
        "Code": "QTY",
        "Reason": "Override Formulary quantity limits"
    },
    {
        "Code": "REDUR",
        "Reason": "Retrospective DUR"
    },
    {
        "Code": "RLE",
        "Reason": "Override Plan refills limit"
    },
    {
        "Code": "SSPLO",
        "Reason": "State Sanction Prescriber Limitation Override"
    },
    {
        "Code": "ST",
        "Reason": "Override Step Therapy"
    },
    {
        "Code": "ST-Administrative",
        "Reason": "Override Step Therapy"
    },
    {
        "Code": "ST-Appeal",
        "Reason": "Override Step Therapy by Appeal"
    },
    {
        "Code": "ST-Emergency",
        "Reason": "Override Step Therapy by Emergency"
    },
    {
        "Code": "ST-LOC",
        "Reason": "Override Step Therapy for LOC"
    },
    {
        "Code": "TIER",
        "Reason": "Override Formulary Tier"
    },
    {
        "Code": "UOM",
        "Reason": "Override Non-matched Unit of Measure"
    },
    {
        "Code": "VCOB",
        "Reason": "Vaccine with COB"
    }
]