//var svgg;
var qsc;
function controlHandler(c) {
    qsc = c;
}

///function svgHandler(q) {
///    svgg = q;
///    var svgd = document.getElementById("svgQuestionDisplay");
///    svgd.addEventListener("mousedown", svgQuestionDisplayDown);
///}
///
///function svgQuestionDisplayDown(e) {
///    if (e.button == 2) {
///        window.addEventListener("mousemove", svgQuestionDisplayMove);
///        window.addEventListener("mouseup", svgQuestionDisplayUp);
///        e.preventDefault();
///    }
///}
///function svgQuestionDisplayMove(e) {
///    var mx = e.movementX;
///    var my = e.movementY;
///    svgg.Move(mx, my);
///}
///function svgQuestionDisplayUp(e) {
///    if (e.button == 2) {
///        window.removeEventListener("mousemove", svgQuestionDisplayMove);
///        window.removeEventListener("mouseup", svgQuestionDisplayUp);
///        //console.log("upper")
///    }
///}
////*******************************TREE*******************************/
///var treeDisplay;
///function treeHandler(div) {
///    treeDisplay = document.getElementById(div);
///    treeDisplay.addEventListener("mousedown", treeDisplayDown);
///}
///function treeDisplayDown(e) {
///    if (e.button == 2) {
///        //console.log(e);
///        window.addEventListener("mousemove", treeDisplayMove);
///        window.addEventListener("mouseup", treeDisplayUp);
///        e.preventDefault();
///    }
///}
///function treeDisplayMove(e) {
///    var mx = e.movementX * -1;
///    var my = e.movementY * -1;
///    //console.log(mx, my);
///    treeDisplay.scrollBy(mx,my)
///    //svgg.Move(mx, my);
///}
///function treeDisplayUp(e) {
///    if (e.button == 2) {
///        window.removeEventListener("mousemove", treeDisplayMove);
///        window.removeEventListener("mouseup",   treeDisplayUp);
///        //console.log("upper")
///    }
///}